// glightbox

const lightbox = GLightbox({
  touchNavigation: true,
  loop: true,
  autoplayVideos: true
});


// hero slider

const swiperHero = new Swiper('.swiper-hero', {
  slidesPerView: 1,
  direction: "horizontal",
  autoplay: true,
  loop: true,
  speed: 3500,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    1100: {
      direction: "vertical",
    }
  }
})

// cards slider

const swiperCards = new Swiper('.swiper-cards', {
  slidesPerView: 1,
  centeredSlides: true,
  grabCursor: true,
  autoplay: false,
  loop: false,
  speed: 1500,
  spaceBetween: 25,
  breakpoints: {
    840: {
      slidesPerView: "auto",
      grabCursor: false,
      spaceBetween: 11,
      centeredSlides: false,
    },
    1350: {
      slidesPerView: 3,
      grabCursor: false,
      spaceBetween: 11,
      centeredSlides: false,
    }
  }
})

// branches slider

const swiperBranches = new Swiper('.swiper-branches', {
  slidesPerView: 1,
  direction: "vertical",
  autoplay: true,
  loop: true,
  speed: 1300,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
})

// highlights slider

const swiperDestaques = new Swiper('.swiper-destaques', {
  slidesPerView: 1,
  autoplay: true,
  grabCursor: false,
  centeredSlides: true,
  loop: false,
  spaceBetween: 60,
  speed: 2000,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    1100: {
      slidesPerView: 4,
      centeredSlides: false,
    }
  }
})

// partner slider

const swiperPartner = new Swiper('.swiper-partner', {
  slidesPerView: "auto",
  autoplay: false,
  centeredSlides: true,
  loop: false,
  spaceBetween: 0,
  speed: 1300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    991: {
      slidesPerView: 7,
      autoplay: true,
      centeredSlides: false,
    }
  }
})
console.log(swiperPartner)

// intro

document.querySelector('.floating-text svg').addEventListener('click', function() {
  // Scroll to the hero section
  const heroSection = document.querySelector('#home .banner-home');
  heroSection.scrollIntoView({ behavior: 'smooth' });
});

let lastScrollPosition = 0;
let ticking = false;

function onScroll() {
  const intro = document.getElementById('intro');
  const introHeight = intro.offsetHeight; // Height of the intro section
  const scrollPosition = window.scrollY || document.documentElement.scrollTop;

  // Check if the bottom of the intro section is at or above the top of the viewport
  if (scrollPosition >= introHeight) {
    // setTimeout(() => {
      intro.style.display = 'none';
    // }, 400)
  }

  lastScrollPosition = scrollPosition;
  ticking = false;
}

window.addEventListener('scroll', function() {
  if (!ticking) {
    window.requestAnimationFrame(onScroll);
    ticking = true;
  }
});

// document.querySelector('.floating-text svg').addEventListener('click', function() {
//   // Calculate 100vh in pixels
//   const viewportHeight = window.innerHeight;

//   // Scroll down by 100vh
//   window.scrollBy({
//     top: viewportHeight, // 100vh
//     // behavior: 'smooth' // Optional: for smooth scrolling
//   });
// });

// window.addEventListener('scroll', () => {
//   const intro = document.getElementById('intro');
//   const introHeight = intro.offsetHeight; // Height of the intro section
//   const scrollPosition = window.scrollY || document.documentElement.scrollTop;

//   // Check if the bottom of the intro section is at or above the top of the viewport
//   if (scrollPosition >= introHeight) {
//     setTimeout(() => {
//       intro.style.display = 'none';
//     }, 500)
//   }
//   // on mouse scroll, scrolls by the intro section (intro cannot have display none)
//   if ( scrollPosition > 100 && intro.style.display !== 'none' ) {
//     // window.scrollBy(0, (introHeight - scrollPosition)); // calc intro height in px's minus scrollY value
//     window.scrollBy({
//       top: (introHeight - scrollPosition)
//     })
//   }
// });